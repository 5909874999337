import _ from 'lodash';
import { useEffect, useState } from 'react';
import { APP_IMG } from 'src/constants';
import { convertDatasets } from 'src/utils/datasets';
import { splitFootballScore, useDateUpdate, useTimeUpdate } from 'src/utils/helper';
import { GET } from 'src/utils/services';
import styled from 'styled-components';

function WidgetScore({ id, data }) {
  if (_.isEmpty(data)) return null;
  const [currentMatch, setCurrentMatch] = useState([]);
  const [previousMatch, setPreviousMatch] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const res = await GET('https://gservice.bangkokbiznews.com/worldcup2022/livescore', true, false);
        if (!_.isEmpty(res?.currentMatch)) setCurrentMatch(res?.currentMatch);
        if (!_.isEmpty(res?.previousMatch)) setPreviousMatch(res?.previousMatch);
      } catch (err) {
        console.log('err');
      }
    })();
  }, []);
  if (_.isEmpty(currentMatch) && _.isEmpty(previousMatch)) return null;

  return (
    <WidgetWrapper id={id}>
      <div className='scroll'>
        {!_.isEmpty(previousMatch) &&
          _.map(previousMatch, (prev, index) => (
            <>
              {index === 0 && (
                <h2 className='heading --prev'>
                  {convertDatasets(data, 'หัวข้อ-1', true)} {useDateUpdate(prev?.timeMatch)}
                </h2>
              )}
              <div key={index} className='list-item'>
                <div className='detail'>
                  {prev.group && <div className='group'> {`กลุ่ม ${prev.group}`} </div>}
                  <span className='time'> {useTimeUpdate(prev?.timeMatch)} </span>
                </div>
                <div className='item-group'>
                  <div className='item-home'>
                    <span className='home-name'>{prev.home_name_th}</span>
                    <img className='home-image' src={`${APP_IMG}/uploads/images/fifa2022/flags/${prev.home_id}.png`} width={70} height={42} />
                  </div>
                  {
                    <span className='score'>
                      <span className='penalty-score'>{!_.isEmpty(prev.ps_score) && `(${splitFootballScore(prev.ps_score).left})`}</span>
                      {prev.score}
                      <span className='penalty-score'>{!_.isEmpty(prev.ps_score) && `(${splitFootballScore(prev.ps_score).right})`}</span>
                    </span>
                  }
                  <div className='item-away'>
                    <img className='away-image' src={`${APP_IMG}/uploads/images/fifa2022/flags/${prev.away_id}.png`} width={70} height={42} />
                    <span className='away-name'>{prev.away_name_th}</span>
                  </div>
                </div>
              </div>
            </>
          ))}
        {!_.isEmpty(currentMatch) &&
          _.map(currentMatch, (cur, index) => (
            <>
              {index === 0 && (
                <h2 className='heading --cur'>
                  {convertDatasets(data, 'หัวข้อ-2', true)} {useDateUpdate(cur?.timeMatch)}
                </h2>
              )}
              <div key={index} className='list-item'>
                <div className='detail'>
                  {cur.group && <div className='group'> {`กลุ่ม ${cur.group}`} </div>}
                  <span className='time'> {useTimeUpdate(cur?.timeMatch)} </span>
                </div>
                <div className='item-group'>
                  <div className='item-home'>
                    <span className='home-name'>{cur.home_name_th}</span>
                    <img className='home-image' src={`${APP_IMG}/uploads/images/fifa2022/flags/${cur.home_id}.png`} width={70} height={42} />
                  </div>
                  <span className='score'>
                    <span className='penalty-score'>{!_.isEmpty(cur.ps_score) && `(${splitFootballScore(cur.ps_score).left})`}</span>
                    {cur.score}
                    <span className='penalty-score'>{!_.isEmpty(cur.ps_score) && `(${splitFootballScore(cur.ps_score).right}`}</span>
                  </span>
                  <div className='item-away'>
                    <img className='away-image' src={`${APP_IMG}/uploads/images/fifa2022/flags/${cur.away_id}.png`} width={70} height={42} />
                    <span className='away-name'>{cur.away_name_th}</span>
                  </div>
                </div>
              </div>
            </>
          ))}
      </div>
    </WidgetWrapper>
  );
}

export default WidgetScore;

const WidgetWrapper = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(263.75deg, rgba(32, 32, 32, 0.5) 5.38%, rgba(32, 32, 32, 0) 100%);
  border-radius: 16px;
  color: #fff;
  padding: 15px;

  .scroll {
    overflow-y: scroll;
    height: 340px;
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
      background-color: #e3dede;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #ffffff21;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #fff;
    }
    @media (max-width: 768px) {
      height: 320px;
    }
  }
  .list-item {
    display: block;
    weight: 100%;
    padding: 0 10px;
    @media (max-width: 768px) {
      padding: 0 10px 0 0;
    }
  }
  .detail {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 6px;

    .time {
      /* font-family: 'Prompt'; */
      font-size: 12px;
      font-weight: 300px;
      padding: 2px 16px;
    }
  }
  .item-group {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas: 'item-home score item-away';
  }
  .group {
    background: rgba(32, 32, 32, 0.5);
    border-radius: 4px;
    padding: 0px 16px;
    font-weight: 700;
    font-size: 14px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  .item-home {
    position: relative;
    grid-area: item-home;
    background: #202020;
    padding: 3px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px 0 0 4px;
    border-left: 4px solid #fff;
    @media (max-width: 768px) {
      padding: 1.5px;
    }
    @media (max-width: 380px) {
      padding: 2px;
    }
  }
  .item-away {
    position: relative;
    grid-area: item-away;

    background: #202020;
    padding: 3px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0 4px 4px 0;
    border-right: 4px solid #fff;
    @media (max-width: 768px) {
      padding: 1.5px;
    }
    @media (max-width: 380px) {
      padding: 2px;
    }
  }
  .away-image {
    border-radius: 6px;
    position: absolute;
    left: 8px;
    margin: 0;
    object-fit: cover;
    width: 70px;
    height: 42px;

    @media (max-width: 480px) {
      left: 3px;
      width: 30px;
      height: 30px;
      border-radius: 50px;
    }
    @media (max-width: 380px) {
      width: 25px;
      height: 25px;
    }
  }
  .away-name {
    text-align: end;
    display: block;
    width: 100%;
    padding-left: 60px;
    font-size: 16px;
    font-weight: 400;
    padding: 0 5px;

    @media (max-width: 480px) {
      font-size: 14px;
    }
    @media (max-width: 380px) {
      font-size: 12px;
    }
  }
  .home-name {
    text-align: start;
    display: block;
    width: 100%;
    padding-right: 60px;
    font-size: 16px;
    font-weight: 400;
    padding: 0 5px;

    @media (max-width: 480px) {
      font-size: 14px;
    }
    @media (max-width: 380px) {
      font-size: 12px;
    }
  }
  .home-image {
    margin: 0;
    border-radius: 6px;
    position: absolute;
    right: 8px;
    object-fit: cover;
    width: 70px;
    height: 42px;

    @media (max-width: 480px) {
      right: 3px;
      width: 30px;
      height: 30px;
      border-radius: 50px;
    }
    @media (max-width: 380px) {
      width: 25px;
      height: 25px;
    }
  }
  .score {
    position: relative;
    grid-area: score;
    text-align: center;
    background: #202020;
    /* font-family: 'Prompt'; */
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.24em;
    @media (max-width: 1024px) {
      font-size: 18px;
      letter-spacing: 0.2em;
    }
    @media (max-width: 480px) {
      font-size: 16px;
    }
    @media (max-width: 380px) {
      padding: 2px 0;
      font-size: 12px;
    }
    .penalty-score {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.75);
      @media (max-width: 1024px) {
        font-size: 9px;
      }
    }
  }
  .heading {
    /* font-family: 'Prompt'; */
    display: block;
    padding: 10px 0;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    &.--cur {
      margin-top: 50px;
    }
    @media (max-width: 480px) {
      font-size: 18px;
    }
    @media (max-width: 380px) {
      font-size: 16px;
    }
  }
`;
